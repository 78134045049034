.footer-container{
    text-align: center;
}
.footer-legal{
    background-color: rgb(41, 41, 41); 
    padding-top: 30px; padding-bottom: 30px; 
}
.footer-legal p{
    font-size: 0.93em;
}

.socialmedia-links{
    padding-top: 50px; padding-bottom: 50px; 
    text-align: center; 
}

.socialmedia-links ul li{
    display: inline; 
}

.socialmedia-links ul{
    margin: 0; 
    padding: 0; 
}

.socialmedia-links img{
    width: 30px; 
    filter: invert(100%); 
    margin-left: 20px; margin-right: 20px; 
    border-radius: 9px; 
    transition: 0.5s; 

}

.socialmedia-links img:hover{
    filter: invert(80%); 
    transition: 0.2s; 
}

.accessibility-links ul{
    padding: 0; 
    width: fit-content; 
    margin-left: auto; margin-right: auto; 
}
.accessibility-links li{
    display: inline; 
    padding: 0 10px 0 10px; 
}
.accessibility-links a{
    color: whitesmoke; 
}
.accessibility-links a:hover{
    color: whitesmoke; 
}