.images{
    display: inline; 
}

.images img{
    padding: 10px; 
    width: 33%; 
    aspect-ratio: 1/1;
    object-fit: cover; 
}

@media only screen and (max-width: 850px){
    .images img{
        width: 33%; 
    }
}

@media only screen and (max-width: 450px){
    .images img{
        width: 100%; 
    }
}