.header-container{
	position: relative; 
	text-align: center;
}
.header-text{
	position: absolute; 
	top: 50%; bottom: 50%;
	width: 50%; 
	transform: translate(50%, -50%); 
}
.header-text a{
	text-decoration: none; 
	color: white; 
	transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.header-text a:hover{
	transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1); 
	text-decoration: none; 
	color: #c8eaff; 
}
.header-text h3{
	text-align: center; 
	font-size: 5em; 
}
.header-img img{
	width: 100%; 
	filter: brightness(80%);
    object-fit: cover; 
	min-height: 100vh; 
    max-height: 100vh;
    z-index: -1;
}

.down-arrow-img{
	position: absolute; 
	bottom: 50px; 
	transform: translate(50vw, 0); 
	transition: 0.5s; 
}

.down-arrow-img img{
	width: 70px; 
	transform: translate(-50%,0);
	transition: 0.5s; 
}

.down-arrow-img img:hover{
	filter: brightness(0.8); 
	transition: 0.5s; 
}

.down-arrow-img:hover{
	transition: 0.5s; 
	transform: translate(50vw, 10px); 
	cursor: pointer;
}

.form-container{
	padding-top: 100px; 
	width: 800px; margin-left: auto; margin-right: auto; 
}
.form-container p{
	font-size: 0.9em; 
}
.form-container input:focus{
	outline:none; 
}

.name-input{
	display:inline; 
	width: 100%; 
}

.name-input-name{
	width: 100%; 
}

.name-input label{
	padding-right: 10px;
	font-size: 0.9em; 
}

.form-input-name{
	display: inline; 
	margin-right: 20px; 
}

.form-input-email{
	width: 100%
}

.form-input-subject{
	width: 100%;
}
.form-input-message{
	width: 100%;
	height: 20vh; 
}

.form-input-message:focus{
	outline: none; 
}

.form-button-submit{
	width: 100px; 
	color: white; 
	background-color: black; 
	border: 2px solid white; 
	transition: 0.5s; 
	
}
.form-button-submit:hover{
	color: black; 
	background-color: white;
	transition: 0.5s; 
}
.form-button-submit:focus{
	outline: none; 
}
.submit-container{
	margin-top: 20px; margin-bottom: 20px; 
	text-align: center; 
}

@media only screen and (max-width: 850px){
	.header-img img{
		object-position: 25% 0;
	}
	.header-text p{
		font-size: small; 
	}

	.header-text{
		width: 100%; 
		transform: translate(0, -50%); 
	}
	
	.form-container{
		width: 100%;
		padding-right: 25px; padding-left:25px; 
	}
	.header-text h3{
		font-size: 2em; 
	}
	.down-arrow-img{
		bottom: 150px; 
	}

}