.navbar-container{
    transition: 0.5s;
    position: fixed;
    top: 0; 
    width: 100%;
    text-align: center;
    z-index: 10;
}

.navbar-navmenu-container li{
    display: inline;
}

.navbar-menulink{
    padding-left: 10px; padding-right: 10px;
    color: rgb(153, 153, 153); 
}

.navbar-container-mobile{
    position: fixed; 
    z-index: 10; 
    width: 100%; 
    transition: 0.5s;
    height: 70px; 
} 


.navbar-navmenu-container-mobile{
    position: absolute;
    width: 100%; 
    height: 100vh; 
    top: 0; 
    margin-left: 0; 
    list-style-type: none;
    padding: 0; 
}
.navbar-navmenu-container-mobile li{
    font-size: 1.3em;  
    text-align: center; 
    margin-left:0; 
    padding-left: 0; 
    height: 6vh; 
    transform: translateY(33vh);
}

.navbar-logo-mobile{
    display: inline;
}


.hamburger-stack{
    display: inline; 
    float: right; 
    height: 30px;
    width: 35px; 
    z-index: 20; 
    margin-right: 20px; 
    margin-top: 20px; 
}