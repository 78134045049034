
.home-info{
    width: 800px; 
    margin-left: auto; margin-right: auto; 
    font-size: 0.92em;
}

.home-biopic{
    width: 100%; 
    height: 100vh; 
    overflow: hidden; 
}

.home-biopic img{
    filter: brightness(90%);
    object-fit: cover; 
    width: 100%; 
    z-index: -1;
}

.home-about{
    padding-top: 100px; 
    
    color: white; 
}

.home-releasesperformances p{
    text-align: center;

}

.home-releasesperformances{
    padding-top: 100px;
    padding-bottom: 40px; 
}

.home-stream{
    padding-top: 100px; 
}
.home-stream-spotify{
    display: flex; 
}
.home-stream iframe{
    margin: 10px; 
    max-height: 200px; 
}

@media only screen and (max-aspect-ratio: 2500/1669){
    .home-biopic{
        width: 100vw; 
        height: 100vh; 
    }
    .home-biopic img{
        height: 100vh; 
    }

    .home-info{
        width: 100%;
        padding-left: 25px; padding-right: 25px; 
    }
    .home-stream-spotify{
        display: inline; 
    }
    .home-stream iframe{
        width: 100%;
    }

}
